import React, { useEffect, useState } from "react";
import { Card, Empty, Space } from "antd";

import CONSTS from "../config/config";
import axios from "axios";

export default function PCRechargeCancel() {
  const [canceledOrders, setCancelOrders] = useState([]);
  const fetchRechargeOrders = (status) => {
    axios({
      method: "get",
      url: CONSTS.HTTPURL + "/api/recharge/all",
      withCredentials: true,
      params: {
        status,
      },
    }).then((res) => {
      const { data } = res;
      console.log(data.data);
      setCancelOrders(data.data);
    });
  };
  useEffect(() => {
    fetchRechargeOrders("已取消");
  }, []);
  return (
    <>
      {canceledOrders.length === 0 ? <Empty /> : ""}
      {canceledOrders.map((order) => {
        return (
          <Card
            title={"账号【" + order.username + "】充值 $" + order.value + ""}
            size="small"
            key={order.id}
          >
            <p>订单号：{order.id}</p>
            <p>账号：{order.username}</p>
            <p>金额：{order.value}</p>
            <p>需要支付：{order.needPayUsdtValue}</p>
            <p>订单创建时间：{new Date(order.createTime).toLocaleString()}</p>
            <p>
              状态：
              <span style={{ color: "rgb(255,100,30)" }}>{order.status}</span>
            </p>
          </Card>
        );
      })}
    </>
  );
}
