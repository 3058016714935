import React, { Component, useEffect, useState } from "react";
import "./m-item-detail.css";
import axios from "axios";
import CONSTS from "../config/config";

export default function MItemDetails(props) {
  const type = props.match.params.type;
  const [showBuyOptionPage, setShowBuyOptionPage] = useState(false);
  const itemList = {
    ali: {
      title: "阿里云国际站账号",
      img: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-ali.png",
      details:
        "<div>实名账号：可购买中国内地资源+海外资源，例如：服务器 数据库 CDN </div><div>不实名账号：仅可购买海外资源 </div>",
    },
    tencent: {
      title: "腾讯云国际站账号",
      img: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-tencent.png",
      details:
        "<div>实名账号：可购买中国内地资源+海外资源，例如：服务器 数据库 CDN </div><div>不实名账号：仅可购买海外资源 </div>",
    },
    aws: {
      title: "AWS国际站账号",
      img: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-aws.png",
      details:
        "<div>实名账号：可购买中国内地资源+海外资源，例如：服务器 数据库 CDN </div><div>不实名账号：仅可购买海外资源 </div>",
    },
  };
  const accountType = [
    {
      alias: "实名",
      val: true,
    },
    {
      alias: "非实名",
      val: false,
    },
  ];
  const [currentItem, setCurrentItem] = useState({});
  useEffect(() => {
    setCurrentItem(itemList[type]);
  }, [type]);
  // const [allAccountItems, setAllAccountItems] = useState([]);

  const [submitData, setSubmitData] = useState({
    isKYC: true,
    size: 1,
  });

  const createOrder = () => {
    const item = itemList[type];
    axios({
      method: "post",
      url: CONSTS.HTTPURL + "/api/order/create",
      withCredentials: true,
      data: {
        itemType: type,
        itemName: item.title,
        minImage: item.img,
        customerEmail: "",
        size: String(submitData.size),
        isKYC: submitData.isKYC,
      },
    }).then((res) => {
      const { data } = res;
      if (data.data === null) {
        window.location.href = "/login";
      } else {
        window.location.href = "/payment/unpaid";
      }
    });
  };
  return (
    <div className="ItemDetailPage">
      {/* 购买选项与遮罩 */}
      {showBuyOptionPage && (
        <div
          className="buyOptionPage"
          onClick={(e) => {
            if (e.target.className === "buyOptionPage") {
              setShowBuyOptionPage(false);
            }
          }}
        >
          <div className="buyOptionCard">
            <div className="buyOptionCard-item-1">
              <div>
                <img alt="" src={currentItem.minImage} />
              </div>
              <div
                style={{
                  boxSizing: "border-box",
                  padding: "0 0 0 15px",
                }}
              >
                <div>
                  <span className="token">¥</span>{" "}
                  <span className="price"> todo price</span>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#888",
                      fontWeight: "bold",
                    }}
                  >
                    市场价: ¥ todo
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#888",
                      fontWeight: "bold",
                    }}
                  >
                    类型:
                  </span>{" "}
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "10px",
                      color: "#888",
                      fontWeight: "bold",
                    }}
                  >
                    数量:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    todo
                  </span>
                </div>
              </div>
            </div>
            {/* 区服选择 */}
            <div className="buyOptionCard-item-2">
              <div
                style={{
                  fontSize: "12px",
                  color: "rgba(160,160,162,1)",
                  lineHeight: "17px",
                  fontWeight: "bold",
                  boxSizing: "border-box",
                }}
              >
                类型
              </div>
              <div className="buyOptionCard-item-lables">
                {accountType.map((t) => {
                  return (
                    <div
                      key={t.alias}
                      className={
                        submitData.isKYC === t.val
                          ? "choose-lable-active"
                          : "choose-lable"
                      }
                      onClick={() => {
                        setSubmitData({ ...submitData, isKYC: t.val });
                      }}
                    >
                      {t.alias}
                    </div>
                  );
                })}
                {/* 缺货的 */}
                {/* {sku.invalidArea.map((area) => {
                  return (
                    <div className="choose-invalid-lable" key={area}>
                      {area}
                    </div>
                  );
                })} */}
              </div>
            </div>
            {/* 面额选择 */}
            {/* <div className="buyOptionCard-item-3">
              <div
                style={{
                  fontSize: "12px",
                  color: "rgba(160,160,162,1)",
                  lineHeight: "17px",
                  fontWeight: "bold",
                  boxSizing: "border-box",
                }}
              >
                面额
              </div>
              <div className="buyOptionCard-item-lables">
                {sku.validPrice.map((price) => {
                  return (
                    <div
                      key={price}
                      className={
                        chooseSku.price === price
                          ? "choose-lable-active"
                          : "choose-lable"
                      }
                      onClick={() => {
                        let chooseSku = chooseSku;
                        chooseSku.price = price;
                        this.setState({ chooseSku });
                      }}
                    >
                      {price}
                    </div>
                  );
                })}
                {sku.invalidPrice.map((area) => {
                  return (
                    <div className="choose-invalid-lable" key={area}>
                      {area}
                    </div>
                  );
                })}
              </div>
            </div> */}
            {/* 数量选择 */}
            <div className="buyOptionCard-item-4">
              <div
                style={{
                  fontSize: "12px",
                  color: "rgba(160,160,162,1)",
                  lineHeight: "17px",
                  fontWeight: "bold",
                  boxSizing: "border-box",
                }}
              >
                数量
              </div>
              <div className="buyOptionCard-item-size">
                <img
                  alt=""
                  src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e8c07f9eba784aec958014409c7515aa_mergeImage.png"
                  onClick={() => {
                    let size = submitData.size - 1;
                    if (size > 0) {
                      setSubmitData({ ...submitData, size });
                    }
                  }}
                />
                <span>{submitData.size}</span>
                <img
                  alt=""
                  src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a52afa000b4f461eb6c0dbce3375163d_mergeImage.png"
                  onClick={() => {
                    if (submitData.size >= 5) {
                      return;
                    }
                    let size = submitData.size + 1;
                    setSubmitData({ ...submitData, size });
                  }}
                />
              </div>
            </div>
            {/* 立即购买 */}
            <div className="bottomOption">
              <button
                style={{ width: "100%" }}
                onClick={() => {
                  console.log();
                  if (this.props.user === null) {
                    this.props.changeTab("Login");
                  } else {
                    this.createOrder();
                  }
                }}
              >
                立即购买
              </button>
            </div>
          </div>
        </div>
      )}
      {/* /////////////////////////////////////////////////////////////////////////// */}
      {/* 主页面 */}
      <div className="ItemDetailMain">
        {/* part1导航 */}
        <div
          className="part-1"
          onClick={() => {
            window.location = "/";
          }}
        >
          <img
            alt=""
            src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/left-arrow.png"
            style={{
              width: "18px",
              height: "18px",
              margin: "4px 0 0 16px",
            }}
          />
          <h4>商品详情</h4>
          <img
            alt=""
            src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/share.png"
            style={{
              width: "18px",
              height: "18px",
              margin: "4px  16px 0 0",
            }}
          />
        </div>
        {/* part2大图 */}
        <div className="part-2">
          <img alt="" src={currentItem.img} />
          <div
            style={{
              margin: "5px 0 0 16px",
            }}
          >
            <span className="token">¥</span>{" "}
            {/* <span className="price">{this.props.item.priceRange}</span> */}
            <span className="price">1.00 ~ 26.00</span>
          </div>
          <div
            style={{
              margin: "15px 0 0 16px",
              fontSize: "18px",
              fontWeight: "bold",
              lineHeight: "25px",
            }}
          >
            {/* {this.props.item.name} */}
            {currentItem.title}
          </div>
          <div
            style={{
              margin: "10px 0 0 0",
              padding: "0 16px",
              fontSize: "12px",
              color: "#666",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>适用区域: 国际服</div>
            <div>销量: 2300</div>
          </div>
        </div>
        {/* part3选择规格 */}
        <div
          className="part-3"
          onClick={() => {
            setShowBuyOptionPage(true);
          }}
        >
          <div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                lineHeight: "20px",
              }}
            >
              <span style={{ color: "#999" }}>选择</span>
              <span>实名</span>
              <span>非实名</span>
            </div>
            <div style={{ fontSize: "10px", color: "#666" }}>
              <span>可选实名</span>
            </div>
          </div>
          <div
            style={{
              width: "24px",
              height: "24px",
              padding: "26px 16px 0 0",
            }}
          >
            <img
              alt=""
              src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/big-right-arrow.png"
            />
          </div>
        </div>
        {/* part4商品详情 */}
        <div className="part-4">
          <h4>商品详情</h4>
          {/* <div>{this.props.item.description}</div> */}

          <div dangerouslySetInnerHTML={{ __html: currentItem.details }}></div>
        </div>

        {/* 底部栏 */}
        {!showBuyOptionPage && (
          <div className="bottomOption">
            <div
              onClick={() => {
                this.props.changeTab("kf");
              }}
            >
              <div>
                <img
                  alt=""
                  src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/kf-logo.png"
                />
              </div>
              <div
                style={{
                  fontSize: "12px",
                }}
              >
                客服
              </div>
            </div>
            <div>
              <button
                style={{ padding: "12px 112px 11px 112px" }}
                onClick={() => {
                  setShowBuyOptionPage(true);
                }}
              >
                立即购买
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
