import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";

import PCTopNavbar from "../nav/PCTopNavbar";
import PCHomepage from "../pc-homepage/PCHomepage";
import PCItemDetails from "../item-details/PCItemDetails";
import PCContact from "../pc-contact/PCContact";
import PCLogin from "../pc-login/Login";
import PCRegister from "../pc-register/Register";
import PCOrders from "../pc-payment/PCOrders";
import PCFooter from "../pc-footer/PCFooter";
import PCAccounts from "../accounts-list/PCAccounts";
import MBottomBar from "../nav/MBottomBar";
import MHomepage from "../homepage/MHomepage";
import KF from "../kf/KF";
import MItemDetails from "../item-details/MItemDetails";
import MAccountInfo from "../account-info/MAccountInfo";

// import PcTopBar from "../pc-top-bar/PcTopBar";

export default function App() {
  let isPC = false;
  if (window.device === "pc") {
    isPC = true;
  }
  return (
    <>
      {isPC && (
        <>
          <PCTopNavbar />
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={PCHomepage} />
              <Route
                exact
                path="/buy-account/:type"
                component={PCItemDetails}
              />
              <Route exact path="/homepage" component={PCHomepage} />
              <Route exact path="/contact" component={PCContact} />
              <Route exact path="/login" component={PCLogin} />
              <Route exact path="/register" component={PCRegister} />
              <Route exact path="/accounts/:status" component={PCAccounts} />
              <Route exact path={"/payment/:status"} component={PCOrders} />
            </Switch>
          </BrowserRouter>
          <PCFooter />
        </>
      )}

      {!isPC && (
        <>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={MHomepage} />
              <Route exact path="/buy-account/:type" component={MItemDetails} />
              <Route exact path="/homepage" component={PCHomepage} />
              <Route exact path="/contact" component={KF} />
              <Route exact path="/login" component={PCLogin} />
              <Route exact path="/register" component={PCRegister} />
              <Route exact path="/accounts/:status" component={PCAccounts} />
              <Route exact path="/accountinfo" component={MAccountInfo} />
              <Route exact path={"/payment/:status"} component={PCOrders} />
            </Switch>
          </BrowserRouter>
          <MBottomBar />
        </>
      )}
    </>
  );
}
