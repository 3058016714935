import React, { Component } from "react";
import logo from "./cowracloud.png";
import eye from "./eye.png";
import "./login.css";
import CONSTS from "../config/config";
import axios from "axios";
export default class PCLogin extends Component {
  constructor() {
    super();
    this.emailInputRef = React.createRef();
    this.passwordInputRef = React.createRef();
  }
  render() {
    return (
      <div
        className="pc-login"
        style={{
          backgroundColor: "#fff",
          padding: "0 0 20px 0",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={logo}
            alt="Cowra Cloud"
            style={{
              width: "144px",
              height: "46px",
              margin: "20px 0 0 0",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="text"
            placeholder="请输入邮箱账号"
            ref={this.emailInputRef}
            // className="input-control"
            style={{
              color: "rgba(138, 138, 138, 1)",
              fontSize: "18px",
              lineHeight: "42px",
              margin: "56px 0 0 0",
              border: "none",
              borderBottom: "solid 1px #ccc",
              width: "85vw",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="password"
            placeholder="请输入密码"
            ref={this.passwordInputRef}
            // className="input-control"
            style={{
              color: "rgba(138, 138, 138, 1)",
              fontSize: "18px",
              lineHeight: "42px",
              margin: "28px 0 0 0",
              border: "none",
              borderBottom: "solid 1px #ccc",
              width: "85vw",
            }}
          />
          <img
            src={eye}
            alt=""
            style={{
              width: "18px",
              height: "18px",
              margin: "42px 0 1px -16px",
            }}
            onClick={() => {
              const tags = document.querySelectorAll("input[type=password]");
              tags.forEach((x) => {
                if (x.type === "password") {
                  x.type = "text";
                } else {
                  x.type = "password";
                }
              });
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            boxSizing: "border-box",
            padding: "14px 0 0 0",
            // margin: "14px 0 0 28px",
            fontSize: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "85vw",
            }}
          >
            <span
              style={{
                color: "#ed7730",
                fontWeight: "500",
              }}
              onClick={() => {
                this.props.changeTab("Register");
              }}
            >
              还没有账号？注册账号
            </span>
            <span
              style={{
                color: "#ed7730",
                fontWeight: "500",
              }}
              onClick={() => {
                this.props.changeTab("ForgetPassword");
              }}
            >
              忘记密码
            </span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="button"
            value="登录"
            style={{
              backgroundColor: "rgba(255, 100, 30, 1)",
              borderRadius: "24px",
              margin: "45px 0 0 0",
              padding: "13px 152px 14px 152px",
              border: "none",
              fontWeight: "500",
              color: "white",
              fontSize: "16px",
            }}
            onClick={() => {
              console.log(this.emailInputRef.current.value);
              console.log(this.passwordInputRef.current.value);
              axios({
                method: "post",
                url: CONSTS.HTTPURL + "/api/customer/login",
                withCredentials: true,
                data: {
                  account: this.emailInputRef.current.value,
                  password: this.passwordInputRef.current.value,
                },
              }).then((res) => {
                const { data } = res;
                if (data.message === "登录成功") {
                  // this.props.loginSuccess(data.data);
                  window.location.href = "/";
                }
              });
            }}
          />
        </div>
      </div>
    );
  }
}
