import React, { Component } from "react";

import contact1 from "./asset/pc-home-contact-1.png";
import contact2 from "./asset/pc-home-contact-2.png";
import "./pc-contact.css";
export default class PCContact extends Component {
  // tg://join?invite=c8ynb6MVWqwxM2Zl
  componentDidMount() {
    let tg = "tg://join?invite=c8ynb6MVWqwxM2Zl";
    window.location = tg;
  }
  render() {
    return (
      <div className="pc-contact">
        <div className="banners">
          <div className="pc-contact-banners-item">
            <div className="pc-contact-banners-item-1">
              <img alt="" src={contact1} />
            </div>
            <div className="pc-contact-banners-item-2">
              <h4>获取优惠</h4>
              <img alt="" src={contact2} />
              <div
                style={{
                  padding: "0 25px 0 25px",
                }}
              >
                <div className="pc-contact-banners-item-btn">联系我们</div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textIndent: "2em",
            padding: "40px 80px 0px 80px",
          }}
        >
          随着时代的发展，相较于传统的物理服务器，云服务器更能满足企业和个人的需求。因为其提供了更加灵活、可靠、高效和安全的计算资源，更能适用各种规模的企业和个人应用场景。同时，云服务器允许用户专注于业务发展而不是服务器维护，是现代互联网时代的核心基础设施之一。而考拉云作为阿里云、腾讯云&AWS的第一合作伙伴，有着专业的研发、销售&客服团队，致力于为您提供最优质的专业服务。
        </div>
      </div>
    );
  }
}
