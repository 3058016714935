import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Space,
  Empty,
  Row,
  Col,
  Typography,
  Statistic,
} from "antd";
import axios from "axios";
import CONSTS from "../config/config";
const { Paragraph } = Typography;
const { Countdown } = Statistic;

export default function PCUnpaid() {
  const [unpaidList, setUnpaidList] = useState([]);
  const fetchOrders = (status) => {
    axios({
      method: "get",
      url: CONSTS.HTTPURL + "/api/order/all",
      withCredentials: true,
      params: {
        status,
      },
    }).then((res) => {
      const { data } = res;
      setUnpaidList(data.data);
    });
  };
  useEffect(() => {
    fetchOrders("待支付");
  }, []);
  const onDeadLineFinish = (e) => {
    console.log(e, "finish");
  };
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        padding: "20px",
        display: "flex",
      }}
    >
      {unpaidList.length === 0 ? <Empty /> : ""}
      {unpaidList.map((item) => {
        return (
          <Card
            title={item.itemName + "-" + item.id}
            size="small"
            key={item.id}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Countdown
                  title="剩余时间"
                  value={new Date(item.createTime).getTime() + 30 * 60 * 1000}
                  onFinish={onDeadLineFinish}
                />
                需要支付：
                <Paragraph
                  style={{ color: "rgb(255,100,30", fontWeight: "bold" }}
                  copyable
                >
                  {item.needPayUsdtValue}
                </Paragraph>
                <p>数量：{item.size}</p>
                <p>
                  订单创建时间：{new Date(item.createTime).toLocaleString()}
                </p>
                <p>
                  状态：
                  <span style={{ color: "rgb(255,100,30)" }}>
                    {item.status}
                  </span>
                </p>
                {/* <p> */}
                {/* </p> */}
              </Col>
              <Col span={12}>
                {/* <p> */}
                地址：
                <Paragraph copyable>
                  TEK6PFeZFTUi3XLbSqjnd5AgiyRJtuKqh5
                </Paragraph>
                {/* </p> */}
                <Button
                  onClick={async () => {
                    const { data } = await axios({
                      method: "post",
                      url: `${CONSTS.HTTPURL}/api/order/cancel`,
                      withCredentials: true,
                      data: {
                        id: item.id,
                      },
                    });
                    alert(data.message);
                    setUnpaidList(data.data);
                  }}
                >
                  取消订单
                </Button>
              </Col>
            </Row>
          </Card>
        );
      })}
    </Space>
  );
}
