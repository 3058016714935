import React, { useEffect, useState } from "react";
import CONSTS from "../config/config";
import axios from "axios";
import "./m-account-info.css";

export default function MAccountInfo() {
  const [isLogin, setIsLogin] = useState(false);
  const [username, setUsername] = useState();
  useEffect(() => {
    axios({
      method: "get",
      url: CONSTS.HTTPURL + "/api/customer/info",
      withCredentials: true,
    }).then((res) => {
      const { data } = res;
      if (data.data !== null) {
        setIsLogin(true);
        setUsername(data.data.email);
      }
    });
  }, []);
  return (
    <div className="m-account-info">
      <div
        className="part-1"
        style={{
          padding: "10vh 0 0 0",
          margin: "auto",
          textAlign: "center",
        }}
        onClick={() => {
          window.location = "/login";
        }}
      >
        <img
          src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/head.png"
          alt=""
          style={{
            width: "60px",
            height: "60px",
          }}
        />
        <h6
          style={{
            color: "rgba(51,51,51,1)",
            fontSize: "18px",
            margin: "10px 0 0 0",
          }}
        >
          {isLogin ? username : "登录注册"}
        </h6>
      </div>

      {/* 第二部分 */}
      <div
        style={{
          margin: "5.2vh 15px 2vh 15px ",
          boxSizing: "border-box",
          backgroundColor: "#fff",
        }}
        className="part-2"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "21px 12px 22px 11px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              color: "rgba(51,51,51,1)",
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: "bold",
            }}
          >
            我的订单
          </div>
          <div
            style={{
              color: "rgba(160,160,162,1)",
              fontSize: "12px",
              lineHeight: "17px",
            }}
          >
            查看全部订单 {">"}{" "}
          </div>
        </div>
        <hr
          style={{
            border: "none",
            height: "1px",
            backgroundColor: "#eee",
            width: "94%",
            margin: "0 0 0 3%",
          }}
        />
        <div
          style={{
            display: "grid",
            textAlign: "center",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <div
            onClick={() => {
              this.props.goToOrderList("待支付");
            }}
          >
            <img
              src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/person-1.png"
              alt=""
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <div className="part-2-text">进行中</div>
          </div>
          <div
            onClick={() => {
              this.props.goToOrderList("已完成");
            }}
          >
            <img
              src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/person-2.png"
              alt=""
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <div className="part-2-text">已完成</div>
          </div>
          <div
            onClick={() => {
              this.props.goToOrderList("已取消");
            }}
          >
            <img
              src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/person-3.png"
              alt=""
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <div className="part-2-text">已取消</div>
          </div>
        </div>
      </div>
      {/* 第三部分 */}
      <div
        style={{
          margin: "0 15px 5.2vh 15px ",
          backgroundColor: "#fff",
          padding: "17px 12px 16px 11px",
          boxSizing: "border-box",
        }}
      >
        <div className="part-3-item">
          <div>账户中心</div>
          <img
            alt=""
            src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/right-arrow.png"
          />
        </div>
        <div className="part-3-item">
          <div>帮助中心</div>
          <img
            alt=""
            src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/right-arrow.png"
          />
        </div>
        <div className="part-3-item">
          <div>意见反馈</div>
          <img
            alt=""
            src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/right-arrow.png"
          />
        </div>
        <div className="part-3-item">
          <div>设置</div>
          <img
            alt=""
            src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/right-arrow.png"
          />
        </div>
      </div>
    </div>
  );
}
