import React, { Component } from "react";
import "./kf.css";
export default class KF extends Component {
  render() {
    return (
      <div
        style={{
          background:
            "url(https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part1.jpeg) repeat-x",
        }}
      >
        <div
          style={{
            width: "50vw",
            margin: "auto",
            padding: "30vh 0 0 0",
          }}
        >
          <img
            src="https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/cowra-logo.png"
            alt=""
            style={{
              width: "50vw",
              margin: "auto",
            }}
          />
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          Telegram:{" "}
          <a
            rel="noreferrer"
            style={{
              color: "rgba(255, 100, 30, 1)",
            }}
            href="https://t.me/+c8ynb6MVWqwxM2Zl"
            target="_blank"
          >
            CowraCloud Official
          </a>
        </div>
      </div>
    );
  }
}
