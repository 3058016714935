import React, { useEffect, useState } from "react";

import axios from "axios";
import CONSTS from "../config/config";
import { Card, Empty, Space } from "antd";

export default function PCCancel() {
  const [unpaidList, setUnpaidList] = useState([]);
  const fetchOrders = (status) => {
    axios({
      method: "get",
      url: CONSTS.HTTPURL + "/api/order/all",
      withCredentials: true,
      params: {
        status,
      },
    }).then((res) => {
      const { data } = res;
      setUnpaidList(data.data);
    });
  };
  useEffect(() => {
    fetchOrders("已取消");
  }, []);
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        padding: "20px",
        display: "flex",
      }}
    >
      {unpaidList.length === 0 ? <Empty /> : ""}
      {unpaidList.map((item) => {
        return (
          <Card
            title={item.itemName + "-" + item.id}
            size="small"
            key={item.id}
          >
            <p>数量：{item.size}</p>
            <p>需要支付：{item.needPayUsdtValue}</p>
            <p>订单创建时间：{new Date(item.createTime).toLocaleString()}</p>
            <p>
              状态：
              <span style={{ color: "rgb(255,100,30)" }}>{item.status}</span>
            </p>
          </Card>
        );
      })}
    </Space>
  );
}
