import React from "react";
import "./m-bottom-bar.css";

export default function MBottomBar() {
  const bottomBarList = [
    {
      id: "/",
      text: "首页",
      activeIcon:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/homepage-active.png",
      icon: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/homepage.png",
    },
    {
      id: "/contact",
      text: "客服",
      activeIcon:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/kf.png",
      icon: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/kf.png",
    },
    {
      id: "/accountinfo",
      text: "我的",
      activeIcon:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/personal-page-active.png",
      icon: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/personal-page.png",
    },
  ];
  const barCurrentPage = "/" + window.location.pathname.split("/").splice(1)[0];
  console.log(barCurrentPage);
  return (
    <div className="m-bottom-bar">
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          bottom: "0",
          left: "0",
        }}
      >
        {bottomBarList.map((tab) => {
          return (
            <li
              className={barCurrentPage === tab.id ? "bar-active" : ""}
              key={tab.id}
              style={{
                flex: "1",
                textAlign: "center",
                fontSize: "10px",
                lineHeight: "10px",
              }}
              onClick={() => {
                window.location.href = tab.id;
                // tab.id;
              }}
            >
              <img
                src={barCurrentPage === tab.id ? tab.activeIcon : tab.icon}
                alt=""
                style={{
                  margin: "12px 0 5px 0",
                  width: "22px",
                }}
              />
              <div>{tab.text}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
