// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuzW5JfkdcOvYpK0LqEWW1UgoNur6m7oU",
  authDomain: "cowracloud.firebaseapp.com",
  projectId: "cowracloud",
  storageBucket: "cowracloud.appspot.com",
  messagingSenderId: "130866970809",
  appId: "1:130866970809:web:93716a35b8b61757a29b68",
  measurementId: "G-Y33564KN99",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;
