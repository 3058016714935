import React, { useEffect, useState } from "react";
import "./pc-purchase.css";
import axios from "axios";
import { Card, Button, Empty, Space } from "antd";
import CONSTS from "../config/config";
// TODO 带上/payment/purchase路由
export default function PCComplete() {
  const [purchaseList, setPurchaseList] = useState([]);
  const [accountList, setAccountList] = useState({});

  const fetchOrders = (status) => {
    axios({
      method: "get",
      url: CONSTS.HTTPURL + "/api/order/all",
      withCredentials: true,
      params: {
        status,
      },
    }).then((res) => {
      const { data } = res;

      setPurchaseList(data.data);
    });
  };
  useEffect(() => {
    fetchOrders("已完成");
  }, []);
  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        padding: "20px",
        display: "flex",
      }}
    >
      {purchaseList.length === 0 ? <Empty /> : ""}

      {purchaseList.map((item) => {
        return (
          <Card
            title={item.itemName + "-" + item.id}
            size="small"
            key={item.id}
          >
            <div className="purchase-list-card-container">
              <div>
                <p>
                  <span>数量：</span>
                  {item.size}
                </p>
                <p>
                  <span>需要支付：</span>
                  {item.needPayUsdtValue}
                </p>
                <p>
                  <span>订单创建时间：</span>
                  {new Date(item.createTime).toLocaleString()}
                </p>
                <p>
                  <span>状态：</span>
                  <span style={{ color: "rgb(255,100,30)" }}>
                    {item.status}
                  </span>
                </p>
              </div>
              <div>
                <Button
                  onClick={async () => {
                    const orderId = item.id;

                    // <!-- Event snippet for 购买账号付款成功的订单 conversion page -->
                    window.gtag("event", "conversion", {
                      send_to: "AW-11259975718/owiqCK7Bh84YEKawlvkp",
                      value: item.needPayUsdtValue,
                      currency: "USD",
                      transaction_id: orderId,
                    });
                    const req = await axios({
                      method: "get",
                      url: CONSTS.HTTPURL + "/api/order/cloudAccount",
                      withCredentials: true,
                      params: {
                        orderId,
                      },
                    });
                    const { data } = req;
                    const currentAccounts = { [orderId]: data.data };
                    setAccountList({ ...accountList, ...currentAccounts });
                  }}
                >
                  查看账号
                </Button>
                {accountList[item.id]
                  ? accountList[item.id].map((account) => {
                      return (
                        <div
                          className="purchase-list-card-accounts"
                          key={account.username}
                        >
                          <p>
                            <span>账号：</span>
                            <i>{account.username}</i>
                          </p>
                          <p>
                            <span>密码：</span>
                            <i>{account.password}</i>
                          </p>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </Card>
        );
      })}
    </Space>
  );
}
