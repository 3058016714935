import { Component } from "react";
import logo from "./cowracloud.png";
import "./homepage.css";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation, Pagination, EffectCreative } from "swiper";
import "swiper/swiper-bundle.css";

export default function MHomepage() {
  const bannerImg = [
    {
      id: "banner1",
      url: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/banner1.png",
    },
    {
      id: "banner2",
      url: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/banner2.png",
    },
  ];
  const recommandItems = [
    {
      id: "ali",
      name: "阿里云实名账号",
      url: "/buy-account/ali",
      minImage:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-ali.png",
      image:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-ali.png",
      price: 65.99,
      priceRange: "12.00 ~174.00",
      hot: true,
      description:
        "阿里云实名账号阿里云实名账号阿里云实名账号阿里云实名账号阿里云实名账号",
    },
    {
      id: "tencent",
      name: "腾讯云实名账号",
      url: "/buy-account/tencent",
      minImage:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-tencent.png",
      image:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-tencent.png",
      price: 65.99,
      priceRange: "12.00 ~174.00",
      hot: false,
      description:
        "腾讯云实名账号腾讯云实名账号腾讯云实名账号腾讯云实名账号腾讯云实名账号",
    },
    {
      id: "aws",
      name: "AWS实名账号",
      url: "/buy-account/aws",
      minImage:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-aws.png",
      image:
        "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-aws.png",
      price: 65.99,
      priceRange: "12.00 ~174.00",
      hot: true,
      description:
        "AWS实名账号AWS实名账号AWS实名账号AWS实名账号AWS实名账号AWS实名账号AWS实名账号",
    },
  ];
  return (
    <div className="HomePage">
      <header
        style={{
          // position: "relative",
          // width: "92vw",
          padding: "45px 0 30px 15px",
          boxSizing: "border-box",
          height: "30px",
          display: "flex",
        }}
      >
        <img
          style={{ width: "22.4%", height: "30px" }}
          src={logo}
          alt="Cowra Cloud"
        />
        <input
          type="text"
          style={{
            width: "66.4%",
            height: "30px",
            borderRadius: "16px",
            border: "none",
            paddingLeft: "16px",
          }}
          placeholder="请输入你想找的关键词"
        />
      </header>

      {/* 轮播 */}
      <div>
        <Swiper
          style={{
            height: "160px",
            marginTop: "22px",
            boxSizing: "border-box",

            width: "345px",
            // marginLeft: "4vw",
          }}
          loop={true} //设置循环轮播
          // spaceBetween={-200} //设置堆叠轮播，item之间叠的距离
          // slidesPerView="auto" //设置显示的数量
          modules={[Navigation, Pagination, EffectCreative]}
          // navigation={true} //modules上加了同时要设置为true，才显示
          grabCursor={true}
          // effect={"creative"} //modules上加了同时要设置
          centeredSlides={true} // 居中的slide是否标记为active，默认是最左active,这样样式即可生效
          slideToClickedSlide={false} // 点击的slide会居中
          onClick={(swiper, event) => {
            //点击事件
            //回调函数，当你点击或轻触Swiper 后执行，相当于tap。
            //接受swiper实例和touchend事件作为参数。
            //Swiper5版本之前会有300ms延迟。
          }}
          onTouchEnd={(swiper, event) => {
            //回调函数，当碰触到slider时执行。可选Swiper实例和touchstart事件作为参数。
          }}
        >
          {bannerImg.map((item) => (
            <SwiperSlide
              key={item.id}
              style={
                {
                  // width: "92vw"
                }
              }
            >
              <div
                onClick={(e) => {
                  // swiperClick(e, index);
                  console.log("dddd click");
                }}
                className="swiper-img"
                style={{
                  background: `url(${item.url}) center no-repeat`,
                  width: "100%",
                  height: "100%",
                  backgroundSize: "cover",
                }}
              ></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* part3 */}
      <div
        style={{
          width: "100%",
          maxWidth: "420px",
          boxSizing: "border-box",
          padding: "20px  15px 0px 15px",
          height: "auto",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          // gridTemplateRows: "16vw",
          // gridColumnGap: "10px",
        }}
      >
        <div
          style={{
            width: "108px",
            height: "108px",
            boxSizing: "border-box",
            // margin: "auto",
            padding: "5px 3px 5px 8px",
            background:
              "url(https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part3-1.jpeg) 100% no-repeat",
          }}
          onClick={() => {
            console.log("click aliyun");
          }}
        >
          <span
            style={{
              color: "rgba(255,127,0,1)",
              fontSize: "16px",
              fontWeight: "bolder",
            }}
          >
            阿里云
            <br />
            实名账号
          </span>
          <img
            alt=""
            src={
              "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part3-ali.jpeg"
            }
            style={{
              margin: "26px 0 0 48px",
            }}
          ></img>
        </div>

        <div
          style={{
            width: "224px",
            height: "108px",
            margin: "auto",
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "53px 53px",
            gridRowGap: "5px",
          }}
        >
          <div
            style={{
              boxSizing: "border-box",
              padding: "5px 3px 5px 8px",
              background:
                "url(https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part3-2.jpeg) 100% no-repeat",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              console.log("click tencent");
            }}
          >
            <span
              style={{
                color: "rgba(3,115,252,1)",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
            >
              腾讯云实名账号
            </span>

            <img
              alt=""
              style={{
                height: "32px",
                width: "45px",
                marginTop: "14px",
              }}
              src={
                "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part3-tencent.jpeg"
              }
            ></img>
          </div>
          <div
            style={{
              boxSizing: "border-box",
              padding: "5px 3px 5px 8px",
              background:
                "url(https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/par3-3.jpeg) 100% no-repeat",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                color: "rgba(255,127,0,1)",
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => {
                console.log("click aws");
              }}
            >
              AWS账号
            </span>
            <img
              alt=""
              src={
                "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part3-aws.png"
              }
              style={{
                width: "69px",
                height: "22px",
                marginTop: "26px",
              }}
            ></img>
          </div>
        </div>
      </div>

      {/* 推荐商品 */}

      <h6
        style={{
          fontSize: "16px",
          lineHeight: "22px",
          margin: "12px 0 0 15px",
        }}
      >
        推荐商品
      </h6>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridColumnGap: "5px",
          gridRowGap: "10px",
          margin: "10px 0 0 15px",
        }}
      >
        {recommandItems.map((item) => {
          return (
            <div
              key={item.id}
              style={{
                width: "170px",
              }}
              onClick={() => (window.location = item.url)}
            >
              {item.hot && (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "rgba(255, 100, 30, 1)",
                    borderRadius: "0px 6px 0px 7px",
                    color: "#fff",
                    fontSize: "11px",
                  }}
                >
                  热卖
                </div>
              )}

              <img
                style={{
                  width: "100%",
                }}
                alt=""
                src={item.minImage}
              />
              <div
                style={{
                  fontSize: "14px",
                  color: "rgba(31,45,61,1)",
                  lineHeight: "20px",
                }}
              >
                {item.name}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: " rgba(255,100,30,1)",
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    fontSize: "10px",
                  }}
                >
                  ¥
                </span>{" "}
                {item.price}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
