import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./base/App";
import reportWebVitals from "./base/reportWebVitals";
import analytics from "./firebase";

console.log("analytics", analytics);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
// ReactDOM.render(
//   React.createElement(
//     "p",
//     {
//       id: "first",
//       className: "firstc",
//     },
//     "dswa"
//   ),
//   document.getElementById("root")
// );
