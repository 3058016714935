import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Button, Layout, Menu } from "antd";
import {
  BarsOutlined,
  AccountBookOutlined,
  CloudOutlined,
} from "@ant-design/icons";
import PCAccountsList from "./PCAccountsList";
import "./pc-accounts.css";
import PCRechargePurchase from "./PCRechargePurchase";
import PCRechargeUnpaid from "./PCRechargeUnpaid";
import PCRechargeCancel from "./PCRechargeCancel";

const { Content, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("账号列表", "list", <CloudOutlined />),
  getItem("充值订单", "recharge-orders-list", <BarsOutlined />, [
    getItem("待支付", "unpaid"),
    getItem("已完成", "purchase"),
    getItem("已取消", "cancel"),
  ]),
];

export default function PCAccounts(props) {
  const status = props.match.params.status;
  let currentMenuKeys = status;

  const [openKeys, setOpenKeys] = useState(["recharge-orders-list"]);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="pc-accounts">
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo-vertical" />
          <Menu
            openKeys={openKeys}
            theme="light"
            defaultSelectedKeys={[currentMenuKeys]}
            mode="inline"
            items={items}
            onClick={({ key }) => {
              console.log(key);
              window.location = "/accounts/" + key;
            }}
          />
        </Sider>
        <Layout>
          {currentMenuKeys === "list" && <PCAccountsList />}
          {currentMenuKeys === "unpaid" && <PCRechargeUnpaid />}
          {currentMenuKeys === "purchase" && <PCRechargePurchase />}
          {currentMenuKeys === "cancel" && <PCRechargeCancel />}
        </Layout>
      </Layout>
    </div>
  );
}
