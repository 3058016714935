import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import "./pc-item-details.css";
import axios from "axios";
import CONSTS from "../config/config";

export default function PCItemDetails(props) {
  const type = props.match.params.type;
  const itemList = {
    ali: {
      title: "阿里云国际站账号",
      img: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-ali.png",
      details:
        "<div>实名账号：可购买中国内地资源+海外资源，例如：服务器 数据库 CDN </div><div>不实名账号：仅可购买海外资源 </div>",
    },
    tencent: {
      title: "腾讯云国际站账号",
      img: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-tencent.png",
      details: "tencent国际站账号",
    },
    aws: {
      title: "AWS国际站账号",
      img: "https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part4-aws.png",
      details: "AWS国际站账号AWS国际站账号",
    },
  };
  const accountType = [
    {
      alias: "实名",
      val: true,
    },
    {
      alias: "非实名",
      val: false,
    },
  ];
  const [currentItem, setCurrentItem] = useState({});
  useEffect(() => {
    setCurrentItem(itemList[type]);
  }, [type]);
  // const [allAccountItems, setAllAccountItems] = useState([]);

  const [submitData, setSubmitData] = useState({
    isKYC: true,
    size: 1,
  });

  const createOrder = () => {
    const item = itemList[type];
    axios({
      method: "post",
      url: CONSTS.HTTPURL + "/api/order/create",
      withCredentials: true,
      data: {
        itemType: type,
        itemName: item.title,
        minImage: item.img,
        customerEmail: "",
        size: String(submitData.size),
        isKYC: submitData.isKYC,
      },
    }).then((res) => {
      const { data } = res;
      if (data.data === null) {
        window.location.href = "/login";
      } else {
        window.location.href = "/payment/unpaid";
      }
    });
  };

  // const chooseKYC = (e) => {
  //   setChooseSku({ ...chooseSku, isKYC: e.target.checked });
  // };
  return (
    <div className="pc-item-details">
      <div className="pc-item-details-breadcrumb">
        <a href="/" target="_self">
          首页
        </a>
        {" > "}
        <a target="_self">{currentItem.title}（自动发货）</a>
      </div>
      <div className="pc-item-details-part2">
        <div>
          <img
            alt=""
            style={{
              width: "549px",
              height: "299px",
            }}
            src={currentItem.img}
          />
        </div>
        <div className="pc-item-details-part2-right">
          <div style={{ fontSize: "24px", fontWeight: "bold" }}>
            {currentItem.title}
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "rgb(160,160,162)",
              margin: "10px 0 0 0",
            }}
          >
            该商品为兑换码发货，支付后可自动提取兑换码，提取后第一时间兑换
          </div>
          <div style={{ margin: "10px 0 0 0" }}>
            <span>$</span>
            <span
              style={{
                color: "rgb(255, 100, 30, 1)",
                fontWeight: "bold",
              }}
            >
              1.00-26.00
            </span>
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "rgb(160,160,162)",
              fontWeight: "bold",
              margin: "30px 0 0 0",
            }}
          >
            类型
          </div>

          <div
            style={{
              display: "flex",
              margin: "10px 0 0 0",
              // justifyContent: "space-around",
            }}
          >
            {accountType.map((t) => {
              return (
                <div
                  className={
                    submitData.isKYC === t.val
                      ? "choose-lable-active"
                      : "choose-lable"
                  }
                  key={t.alias}
                  style={{
                    fontSize: "14px",
                    padding: "2px 15px 2px 15px",
                    borderRadius: "16px",
                    margin: "0px 10px 0 0",
                  }}
                  onClick={() => {
                    setSubmitData({ ...submitData, isKYC: t.val });
                  }}
                >
                  {t.alias}
                </div>
              );
            })}
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "rgb(160,160,162)",
              fontWeight: "bold",
              margin: "10px 0 0 0",
            }}
          >
            数量
          </div>
          <div
            style={{
              display: "flex",
              margin: "10px 0 0 0",
            }}
          >
            <img
              alt=""
              src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/e8c07f9eba784aec958014409c7515aa_mergeImage.png"
              onClick={() => {
                let size = submitData.size - 1;
                if (size > 0) {
                  setSubmitData({ ...submitData, size });
                }
              }}
            />
            <span
              style={{
                lineHeight: "24px",
                color: "rgb(255,100,30)",
                fontWeight: "500",
                margin: "0 10px 0 10px",
              }}
            >
              {submitData.size}
            </span>
            <img
              alt=""
              src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a52afa000b4f461eb6c0dbce3375163d_mergeImage.png"
              onClick={() => {
                if (submitData.size >= 5) {
                  return;
                }
                let size = submitData.size + 1;
                setSubmitData({ ...submitData, size });
              }}
            />
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "rgb(90,90,92)",
              margin: "10px 0 0 0",
            }}
          >
            需支付 $
            <span style={{ color: "rgb(255,100,80)" }}>
              {submitData.isKYC ? submitData.size * 26 : submitData.size}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              margin: "30px 0 0 0",
            }}
          >
            <div
              style={{
                color: "#fff",
                fontWeight: "500",
                backgroundColor: "rgb(255,100,30)",
                borderRadius: "23px",
                padding: "12px 45px 12px 45px",
                fontSize: "14px",
                margin: "0 10px 0 0 ",
              }}
              onClick={createOrder}
            >
              立即购买
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                fontWeight: "500",
                color: "rgb(255,100,30)",
                border: "1px solid rgb(255,100,30)",
                borderRadius: "23px",
                padding: "12px 45px 12px 45px",
                fontSize: "14px",
              }}
            >
              <a
                style={{ color: "rgb(255,100,30)", textDecoration: "none" }}
                href="/contact"
              >
                有问题?联系客服
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* part3 */}
      <div
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          margin: "20px 0 0 0 ",
        }}
      >
        商品详情
      </div>
      <div
        style={{
          backgroundColor: "#FFF",
          padding: "15px 15px 15px 15px",
        }}
        dangerouslySetInnerHTML={{ __html: currentItem.details }}
      ></div>
    </div>
  );
}
