import React, { Component } from "react";
import "./pc-footer.css";
export default class PCFooter extends Component {
  render() {
    return (
      <div className="pc-footer">
        <hr
          className="hr-solid-content"
          data-content="© 2023 Copyright Cowra Cloud"
        />
        <div
          style={{
            fontSize: "10px",
            color: "#666",
            padding: "20px",
            textIndent: "4em",
            textAlign: "center",
          }}
        >
          Cowra Cloud. Ltd.
        </div>
      </div>
    );
  }
}
