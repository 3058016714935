import React, { useEffect, useState } from "react";
import "./pc-top-navbar.css";
import logo from "./cowracloud.png";
import CONSTS from "../config/config";
import axios from "axios";
import { CloudServerOutlined } from "@ant-design/icons";
import { Avatar, Menu, Divider } from "antd";

export default function PCTopNavbar(props) {
  const [selectedKeys, setSelectedKeys] = useState(
    window.location.pathname.split("/").splice(1)
  );
  const onClick = (e) => {
    if (!e.key.endsWith("cloud")) {
      setSelectedKeys([e.key]);
    }
  };
  const topItems = [
    {
      label: (
        <a href="/" target="_self" rel="noopener noreferrer">
          首页
        </a>
      ),
      key: "",
    },

    {
      label: "账号购买",
      key: "buy-account",
      icon: <CloudServerOutlined />,
      children: [
        {
          label: (
            <a href="/buy-account/ali" target="_self" rel="noopener noreferrer">
              阿里云账号
            </a>
          ),
          key: "ali",
        },
        {
          label: (
            <a
              href="/buy-account/tencent"
              target="_self"
              rel="noopener noreferrer"
            >
              腾讯云账号
            </a>
          ),
          key: "tencent",
        },
        {
          label: (
            <a href="/buy-account/aws" target="_self" rel="noopener noreferrer">
              AWS账号
            </a>
          ),
          key: "aws",
        },
      ],
    },
    {
      label: (
        <a href="/accounts/list" target="_self" rel="noopener noreferrer">
          充值
        </a>
      ),
      key: "accounts",
    },
    {
      label: (
        <a
          href="https://www.alibabacloud.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          阿里云国际站
        </a>
      ),
      key: "ali-cloud",
    },
    {
      label: (
        <a
          href="https://www.tencentcloud.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          腾讯云国际站
        </a>
      ),
      key: "tencent-cloud",
    },
    {
      label: (
        <a
          href="https://aws.amazon.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          AWS云服务
        </a>
      ),
      key: "aws-cloud",
    },
    {
      label: (
        <a href="/contact" target="_self" rel="noopener noreferrer">
          联系我们
        </a>
      ),
      key: "contact",
    },
  ];
  const [username, setUsername] = useState();
  useEffect(() => {
    axios({
      method: "get",
      url: CONSTS.HTTPURL + "/api/customer/info",
      withCredentials: true,
    }).then((res) => {
      const { data } = res;
      if (data.data !== null) {
        setUsername(data.data.email);
      }
    });
  }, []);
  return (
    <>
      <div
        className="pc-top-nav-bar"
        style={{
          display: "flex",
          backgroundColor: "#fff",
        }}
      >
        <img
          style={{ width: "135px", height: "50px", margin: "0px 0 0 0 " }}
          src={logo}
          alt="Cowra Cloud"
        />
        <Menu
          onClick={onClick}
          selectedKeys={selectedKeys}
          mode="horizontal"
          items={topItems}
        />
        {/* 登录注册 */}

        {username === undefined ? (
          <ul className="login-register-group">
            <li
              key="login"
              onClick={() => {
                // this.props.clickPCTab("login");
              }}
            >
              <a href="/login" target="_self" style={{ display: "flex" }}>
                <span
                  style={{
                    lineHeight: "22px",
                  }}
                >
                  登录
                </span>
              </a>
            </li>
            <li>/</li>
            <li
              key="register"
              onClick={() => {
                // this.props.clickPCTab("register");
              }}
            >
              <a href="/register" target="_self" style={{ display: "flex" }}>
                <span
                  style={{
                    lineHeight: "22px",
                  }}
                >
                  注册
                </span>
              </a>
            </li>
          </ul>
        ) : (
          <div
            id="user-name"
            style={{
              position: "absolute",
              right: "20px",
              padding: "5px",
              textAlign: "center",
              fontSize: "16px",
              lineHeight: "10px",
              color: "rgb(255,100,80)",
              cursor: "pointer",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "rgb(255,100,80)",
                verticalAlign: "middle",
              }}
              size="middle"
            >
              {username.substring(0, 3)}
            </Avatar>
            <div id="user-profile">
              <div
                style={{
                  color: "#888",
                  padding: "24px 11px 24px 11px",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: "rgb(255,100,80)",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {username.substring(0, 3)}
                </Avatar>{" "}
                {username}
              </div>
              <Divider style={{ margin: 0 }} />
              <div
                className="user-profile-tab"
                onClick={() => {
                  window.location = "/payment/unpaid";
                }}
              >
                我的订单
              </div>
              <Divider style={{ margin: 0 }} />
              <div
                className="user-profile-tab"
                onClick={() => {
                  window.location = "/accounts/list";
                }}
              >
                账号列表
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
