import React from "react";
import ali1 from "./asset/pc-home-ali-1.png";
import ali2 from "./asset/pc-home-ali-2.png";
import tencent1 from "./asset/pc-home-tencent-1.png";
import tencent2 from "./asset/pc-home-tencent-2.png";
import aws1 from "./asset/pc-home-aws-1.png";
import aws2 from "./asset/pc-home-aws-2.png";
import contact1 from "./asset/pc-home-contact-1.png";
import contact2 from "./asset/pc-home-contact-2.png";
import "./pc-homepage.css";

export default function PCHomepage(props) {
  return (
    <div className="pc-homepage">
      <div className="pc-homepage-banners">
        <div className="pc-homepage-banners-item">
          <div className="pc-homepage-banners-item-1">
            <img alt="" src={ali1} />
          </div>
          <div
            className="pc-homepage-banners-item-2"
            onClick={() => {
              window.location = "/buy-account/ali";
            }}
          >
            <h4>阿里云国际站账号</h4>
            <img alt="" src={ali2} />
            <div
              style={{
                padding: "0 25px 0 25px",
              }}
            >
              <div className="pc-homepage-banners-item-btn">立即购买</div>
            </div>
          </div>
        </div>
        {/*腾讯 /> */}
        <div className="pc-homepage-banners-item">
          <div className="pc-homepage-banners-item-1">
            <img alt="" src={tencent1} />
          </div>
          <div
            className="pc-homepage-banners-item-2"
            onClick={() => {
              window.location.href = "/buy-account/tencent";
            }}
          >
            <h4>腾讯云国际站账号</h4>
            <img alt="" src={tencent2} />
            <div
              style={{
                padding: "0 25px 0 25px",
              }}
            >
              <div className="pc-homepage-banners-item-btn">立即购买</div>
            </div>
          </div>
        </div>
        {/* aws */}
        <div className="pc-homepage-banners-item">
          <div className="pc-homepage-banners-item-1">
            <img
              style={{
                padding: "18px 0 0 0",
                height: "40px",
              }}
              alt=""
              src={aws1}
            />
          </div>
          <div
            className="pc-homepage-banners-item-2"
            onClick={() => {
              window.location.href = "/buy-account/aws";
            }}
          >
            <h4>AWS国际站账号</h4>
            <img alt="" src={aws2} />
            <div
              style={{
                padding: "0 25px 0 25px",
              }}
            >
              <div className="pc-homepage-banners-item-btn">立即购买</div>
            </div>
          </div>
        </div>

        {/* 联系 */}
        <div className="pc-homepage-banners-item">
          <div className="pc-homepage-banners-item-1">
            <img alt="" src={contact1} />
          </div>
          <div
            className="pc-homepage-banners-item-2"
            onClick={() => {
              window.location.href = "/contact";
            }}
          >
            <h4>获取优惠</h4>
            <img alt="" src={contact2} />
            <div
              style={{
                padding: "0 25px 0 25px",
              }}
            >
              <div className="pc-homepage-banners-item-btn">立即购买</div>
            </div>
          </div>
        </div>
        {/* --- */}
      </div>
    </div>
  );
}
