import React, { useState } from "react";
import tronAddressPicture from "./tron_address.jpg";
import "./pc-payment.css";
import { Col, Row } from "antd";
import PCUnpaid from "./PCUnpaid";
import PCComplete from "./PCComplete";
import PCCancel from "./PCCancel";
export default function PCOrders(props) {
  const orderStatus = props.match.params.status;
  // const [currentView, setCurrentView] = useState("unpaid");

  return (
    <div className="payment" style={{}}>
      <Row justify="center" align="top">
        <Col span={4}>
          <div className="part-1">
            <div
              className={orderStatus === "unpaid" ? "active" : ""}
              onClick={() => {
                window.location = "/payment/unpaid";
              }}
            >
              待付款
            </div>
            <div
              className={orderStatus === "purchase" ? "active" : ""}
              onClick={() => {
                window.location = "/payment/purchase";
              }}
            >
              已完成
            </div>
            <div
              className={orderStatus === "cancel" ? "active" : ""}
              onClick={() => {
                window.location = "/payment/cancel";
              }}
            >
              已取消
            </div>
          </div>
        </Col>

        <Col span={20}>
          {/* 待付款 */}
          {orderStatus === "unpaid" ? <PCUnpaid /> : ""}
          {/* 已完成 */}
          {orderStatus === "purchase" ? <PCComplete /> : ""}
          {/* 已取消 */}
          {orderStatus === "cancel" ? <PCCancel /> : ""}
        </Col>
      </Row>
    </div>
  );
}
