import React, { Component } from "react";
import logo from "./cowracloud.png";
import eye from "./eye.png";
import "./login.css";
import axios from "axios";
import CONSTS from "../config/config";

export default class Register extends Component {
  constructor() {
    super();
    this.emailInputRef = React.createRef();
    this.passwordInputRef = React.createRef();
    this.passwordDoubleInputRef = React.createRef();
  }
  render() {
    return (
      <div
        className="pc-register"
        style={{
          backgroundColor: "#fff",
          padding: "0 0 20px 0",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={logo}
            alt="Cowra Cloud"
            style={{
              width: "144px",
              height: "46px",
              margin: "20px 0 0 0",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="text"
            placeholder="请输入邮箱账号"
            ref={this.emailInputRef}
            // className="input-control"
            style={{
              color: "rgba(138, 138, 138, 1)",
              fontSize: "18px",
              lineHeight: "42px",
              margin: "56px 0 0 0",
              border: "none",
              borderBottom: "solid 1px #ccc",
              width: "85vw",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <input
              type="password"
              placeholder="请输入密码"
              ref={this.passwordInputRef}
              // className="input-control"
              style={{
                color: "rgba(138, 138, 138, 1)",
                fontSize: "18px",
                lineHeight: "42px",
                margin: "28px 0 0 0",
                border: "none",
                borderBottom: "solid 1px #ccc",
                width: "85vw",
              }}
            />
            <img
              src={eye}
              alt=""
              style={{
                width: "18px",
                height: "18px",
                margin: "1px 0 1px -16px",
              }}
              onClick={() => {
                const tags = document.querySelectorAll("input[type=password]");
                tags.forEach((x) => {
                  if (x.type === "password") {
                    x.type = "text";
                  } else {
                    x.type = "password";
                  }
                });
                // const x = document.getElementById("password");
                // if (x.type === "password") {
                //   x.type = "text";
                // } else {
                //   x.type = "password";
                // }
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="请输入验证码"
              ref={this.passwordDoubleInputRef}
              // className="input-control"
              style={{
                color: "rgba(138, 138, 138, 1)",
                fontSize: "18px",
                lineHeight: "42px",
                margin: "28px 0 0 0",
                border: "none",
                borderBottom: "solid 1px #ccc",
                width: "85vw",
              }}
            />
            <span
              style={{
                color: "rgba(255, 100, 30)",
                fontSize: "8px",
                display: "inline-block",
                position: "absolute",
                bottom: "14px",
                right: "28px",
              }}
              onClick={() => {
                axios({
                  method: "post",
                  url: CONSTS.HTTPURL + "/api/customer/captcha",
                  withCredentials: true,
                  data: {
                    account: this.emailInputRef.current.value,
                  },
                }).then((res) => {
                  const { data } = res;
                  console.log(data);
                  alert(data.message);
                });
              }}
            >
              获取验证码
            </span>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              margin: "14px 0 0 0",
              fontSize: "12px",
              width: "85vw",
            }}
            onClick={() => {
              this.props.changeTab("Login");
            }}
          >
            <span
              style={{
                color: "#ed7730",
                fontWeight: "500",
              }}
            >
              已有账号，去登录
            </span>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <input
              type="button"
              value="注册"
              style={{
                backgroundColor: "rgba(255, 100, 30, 1)",
                borderRadius: "24px",
                margin: "45px 91px 0 0",
                padding: "13px 152px 14px 152px",
                border: "none",
                fontWeight: "500",
                color: "white",
                fontSize: "16px",
              }}
              onClick={() => {
                // console.log(this.emailInputRef.current.value);
                // console.log(this.passwordInputRef.current.value);
                // console.log(this.passwordDoubleInputRef.current.value);
                axios({
                  method: "post",
                  url: CONSTS.HTTPURL + "/api/customer/register",
                  withCredentials: true,
                  data: {
                    account: this.emailInputRef.current.value,
                    password: this.passwordInputRef.current.value,
                    code: this.passwordDoubleInputRef.current.value,
                  },
                }).then((res) => {
                  const { data } = res;
                  console.log(data);
                  alert(data.message);
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
