import tronAddressQRCode from "./tron_address.jpg";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Divider,
  Row,
  Button,
  Layout,
  Modal,
  InputNumber,
  Typography,
  Statistic,
} from "antd";
import {
  AccountBookOutlined,
  AmazonOutlined,
  AliyunOutlined,
  CloudOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "./pc-accounts.css";
import CONSTS from "../config/config";
import axios from "axios";
const { Content } = Layout;
const { Paragraph } = Typography;

const { Countdown } = Statistic;

export default function PCAccountsList() {
  const [myAccountList, setMyAccountList] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: CONSTS.HTTPURL + "/api/account/owner/list",
      withCredentials: true,
    }).then((res) => {
      const { data } = res;
      if (data.data !== null) {
        setMyAccountList(data.data);
        console.log(data.data);
      }
    });
  }, []);
  const [rechargeOrderInfo, setRechargeOrderInfo] = useState({});
  const [showRecharge, setShowRecharge] = useState(false);
  //   modal配置
  const [modalChooseAccount, setModalChooseAccount] = useState({});

  const [open, setOpen] = useState(false);
  const openRechargeModal = (account) => () => {
    setShowRecharge(false);
    console.log(account);
    setModalChooseAccount(account);
    setOpen(true);
  };

  //   充值输入框的金额
  const [rechargeValue, setRechargeValue] = useState(100);

  const onCreateRechargeOrder = async () => {
    const { data } = await axios({
      method: "post",
      url: `${CONSTS.HTTPURL}/api/recharge/create`,
      withCredentials: true,
      data: {
        id: modalChooseAccount.id,
        value: rechargeValue,
      },
    });
    if (data.message === "success") {
      setShowRecharge(true);
      setRechargeOrderInfo(data.data);
    }
  };
  return (
    <>
      <Content
        style={{
          margin: "0 16px",
        }}
      >
        <div
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <Row gutter={[16, 24]}>
            {myAccountList.map((account) => {
              return (
                <Col span={8} key={account.id}>
                  <Card
                    title={typeMapToTitle(account.type, account.isKYC)}
                    bordered={false}
                  >
                    <div>
                      <span className="pc-accounts-label">用户名：</span>
                      <span>{account.username}</span>
                    </div>
                    <div>
                      <span className="pc-accounts-label">密码：</span>
                      <span>{account.password}</span>
                    </div>
                    <Divider />
                    <div style={{ textAlign: "center" }}>
                      <Button
                        danger
                        type="primary"
                        icon={<AccountBookOutlined />}
                        onClick={openRechargeModal(account)}
                      >
                        立即充值
                      </Button>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </Content>
      {/* 弹出充值框 */}
      <Modal
        className="pc-account-list-modal"
        title="充值"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={700}
      >
        <Row>
          <Col span={4}>
            <span className="pc-account-list-modal-label">账号类型:</span>
          </Col>
          <Col span={20}>
            {modalChooseAccount.isKYC ? "实名账号" : "非实名账号"}
          </Col>
          <Col span={4}>
            <span className="pc-account-list-modal-label">UID:</span>
          </Col>
          <Col span={20}>{modalChooseAccount.uinId}</Col>
          <Col span={4}>
            <span className="pc-account-list-modal-label">用户名:</span>
          </Col>
          <Col span={20}>{modalChooseAccount.username}</Col>

          <Col span={4}>
            <span className="pc-account-list-modal-label">账号密码:</span>
          </Col>
          <Col span={20}>{modalChooseAccount.password}</Col>
          <Col span={4}>
            <span className="pc-account-list-modal-label">平台:</span>
          </Col>
          <Col span={20} style={{ color: "rgb(255,100,80)" }}>
            <AliyunOutlined />
            <AmazonOutlined />
            <CloudOutlined />
            {modalChooseAccount.type}
          </Col>
          <Col span={4}>
            <span className="pc-account-list-modal-label">购买时间:</span>
          </Col>
          <Col span={20}>
            {Date(modalChooseAccount.createTime).toLocaleString()}
          </Col>
          <Col span={4}>
            <span className="pc-account-list-modal-label">请输入充值金额:</span>
          </Col>
          <Col span={4}>
            <span style={{ fontSize: "12px" }}>(可充值10~1000)</span>
          </Col>
          <Col span={8}>
            <InputNumber
              addonBefore="$"
              precision={0}
              max={1000}
              min={10}
              defaultValue={100}
              onChange={setRechargeValue}
            />
          </Col>
          <Col span={8}>
            <Button
              danger
              onClick={onCreateRechargeOrder}
              type="primary"
              icon={<CheckOutlined />}
            >
              创建订单
            </Button>
          </Col>
        </Row>
        {showRecharge && (
          <>
            <Row justify={"center"} style={{ margin: "30px 0 0 0" }}>
              <Col span={24}>
                <Countdown
                  style={{ textAlign: "center" }}
                  title="剩余时间"
                  value={
                    new Date(rechargeOrderInfo.createTime).getTime() +
                    30 * 60 * 1000
                  }
                  // onFinish={onDeadLineFinish} // TODO 充值订单倒计时到了后要变灰UI
                />
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <img src={tronAddressQRCode} alt="" width={150} />
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <span className="pc-account-list-modal-label">地址:</span>
              </Col>
              <Col span={20}>
                <Paragraph copyable>
                  TEK6PFeZFTUi3XLbSqjnd5AgiyRJtuKqh5
                </Paragraph>
              </Col>
              <Col span={4}>
                <span className="pc-account-list-modal-label">充值ID:</span>
              </Col>
              <Col span={20}>
                <span style={{ fontSize: "12px" }}>{rechargeOrderInfo.id}</span>
              </Col>
              <Col span={4}>
                <span className="pc-account-list-modal-label">账号:</span>
              </Col>
              <Col span={20}>
                <span style={{ fontSize: "12px" }}>
                  {rechargeOrderInfo.username}
                </span>
              </Col>
              <Col span={4}>
                <span className="pc-account-list-modal-label">UID:</span>
              </Col>
              <Col span={20}>
                <span style={{ fontSize: "12px" }}>
                  {rechargeOrderInfo.uinId}
                </span>
              </Col>
              <Col span={4}>
                <span className="pc-account-list-modal-label">支付金额:</span>
              </Col>
              <Col span={20}>
                <span style={{ fontSize: "12px" }}>
                  {rechargeOrderInfo.needPayUsdtValue}
                </span>
              </Col>
              <Col span={4}>
                <span className="pc-account-list-modal-label">创建时间:</span>
              </Col>
              <Col span={20}>
                <span style={{ fontSize: "12px" }}>
                  {rechargeOrderInfo.createTime}
                </span>
              </Col>
              <Col span={4}>
                <span className="pc-account-list-modal-label">支付金额:</span>
              </Col>
              <Col span={20}>
                <span style={{ fontSize: "12px" }}>
                  {rechargeOrderInfo.value}
                </span>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
}

const typeMapToTitle = (type, isKYC) => {
  let res = "";
  switch (type) {
    case "ali":
      res += "阿里云";
      break;
    case "aws":
      res += "AWS";
      break;
    case "tencent":
      res += "腾讯云";
      break;
  }
  isKYC ? (res += "实名账号") : (res += "非实名账号");
  return res;
};
